import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import useLanguage from "../../../Hooks/useLanguage";
import PriceSectionFooter from "./PriceSectionFooter/PriceSectionFooter";

const PricesContent = ({ dataPrices, suggestions, loading, dataSlug }) => {
  const langToggle = useLanguage;
  const pricesData = suggestions.map((p) => p.Price);

  const result = [...new Set(pricesData.flat())];

  const sectionHours = {
    title_ua: "Пн - СБ / 09:00 - 18:00",
    title_ru: "Пн - СБ / 09:00 - 18:00",
    title_en: "Mon - Sat / 09:00 - 18:00",
  };

  return (
    <section className="prices-content">
      <div className="container-fluid">
        <ul className="prices-list">
          {(result || []).map((item, index) => {
            const count = index + 1;

            if (item.title_ua || item.title_ru || item.title_en) {
              return (
                <li className="prices-list__item" key={index}>
                  {/* <Link to={'/' + dataSlug?.slug} className="prices-list__link"> */}
                  <Link to={"#" + dataSlug?.slug} className="prices-list__link">
                    <span className="prices-list__count">
                      {count <= 9 ? "0" + count + " /" : count + " /"}
                    </span>
                    <span className="prices-list__wrapper">
                      <h2 className="prices-list__title">
                        {langToggle(
                          item.title_ua,
                          item.title_ru,
                          item.title_en
                        )}
                      </h2>
                      <p className="prices-list__description">
                        {langToggle(
                          item.sub_title_ua,
                          item.sub_title_ru,
                          item.sub_title_en
                        )}
                      </p>
                    </span>
                    <span className="prices-list__price">
                      ----
                      {/* {langToggle(item.price, item.price, item.price_en)} */}
                    </span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li className="prices-list__item" key={index}>
                  {/* <Link to={'/' + dataSlug?.slug} className="prices-list__link"> */}
                  <span className="prices-list__count">
                    {count <= 9 ? "0" + count + " /" : count + " /"}
                  </span>
                  <span className="prices-list__wrapper">
                    <h2 className="prices-list__title">
                      {langToggle(item.title_ua, item.title_ru, item.title_en)}
                    </h2>
                  </span>
                  <span className="prices-list__price">
                    {/* {langToggle(item.price, item.price, item.price_en)} */}
                  </span>
                  {/* </Link> */}
                </li>
              );
            }
          })}
        </ul>
        <PriceSectionFooter sectionHours={sectionHours} />
      </div>
    </section>
  );
};

export default PricesContent;
