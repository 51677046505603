import React from 'react';
import useLanguage  from '../components/Hooks/useLanguage';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';

// import dataPrice from '../db/dataPrice';
import PricesPage from '../components/Pages/PricesPage/PricesPage';
import Instagram from '../components/Layout/Instagram/Instagram';

const Prices = ({data}) => {
  const langToggle = useLanguage;
// console.log(data?.allStrapiServices.nodes);
  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Ціни на послуги',
      title_ru: 'Цены на услуги',
      title_en: 'Service prices',
      to: '/prices/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo 
        title={langToggle(
          data?.strapiPrice?.seo_title_ua,
          data?.strapiPrice?.seo_title_ru,
          data?.strapiPrice?.seo_title_en
        )} 
          description={
            langToggle(
            data?.strapiPrice?.seo_description_ua,
            data?.strapiPrice?.seo_description_ru,
            data?.strapiPrice?.seo_description_en
          )}
      />
      {/* <PricesMain
        background={data.placeholderImage.childImageSharp.fluid}
        crumbsNav={crumbsNav}
        mainTitle={mainTitle}
      />
      <PricesContent dataPrices={dataPrices} /> */}
      <PricesPage
        dataSlug={data?.strapiServices?.nodes}
        dataPrices={data?.allStrapiServices?.nodes}
        background={data?.strapiPrice}
        crumbsNav={crumbsNav}
        mainTitle={data?.strapiPrice}
      />
      <Instagram />
    </Layout>
  );
};

export default Prices;

export const query = graphql`
 query PricePage($language: String!) {
  strapiPrice {
    seo_title_ua
    seo_title_ru
    seo_title_en
    seo_description_ua
    seo_description_ru
    seo_description_en
    title_ua
    title_ru
    title_en
    image {
      localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
    }
  }
  allStrapiServices {
    nodes {
      Price {
        title_ua
        title_ru
        title_en
        price_en
        price
        id
      }
    }
  }
  locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`