import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

import ButtonBook from '../../../../UI/ButtonBook';

const PriceSectionFooter = ({ sectionHours }) => {
  const langToggle = useLanguage;

  function book() {
    console.log('Test Succes');
  }
  return (
    <div className="section-footer">
      <p className="work-hours">
        {langToggle('Працюємо:', 'Работаем:', 'We work:')}
        <span className="hours">
          {langToggle(
            sectionHours.title_ua,
            sectionHours.title_ru,
            sectionHours.title_en
          )}
        </span>
      </p>
      <ButtonBook
        clickHendler={book}
        title={langToggle(
          'Записатись на консультацію',
          'Записаться на консультацию',
          'Book a Consultation'
        )}
      />
    </div>
  );
};

export default PriceSectionFooter;
