import React from 'react';
import { useContext } from 'react';
import { I18nContext } from 'gatsby-plugin-react-i18next';

import PricesMain from './PricesMain/PricesMain';
import PricesContent from './PricesContent/PricesContent';

const PricesPage = ({ dataPrices, background, crumbsNav, mainTitle, dataSlug }) => {
  // const prices = dataPrices.map(p => p.Price)
  // console.log(prices);
  const {
    i18n: { language },
  } = useContext(I18nContext);

  const [query, setQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);

  React.useEffect(() => {
    if (query === '') {
      setSuggestions(dataPrices);
    } else {
      let val = dataPrices
        .filter((item) => {
          if (language === 'ua') {
            return item.title_ua.toLowerCase().indexOf(query.toLowerCase()) !==
              -1
              ? true
              : false;
          } else if (language === 'ru') {
            return item.title_ru.toLowerCase().indexOf(query.toLowerCase()) !==
              -1
              ? true
              : false;
          } else {
            return item.title_en.toLowerCase().indexOf(query.toLowerCase()) !==
              -1
              ? true
              : false;
          }
        })
        .map((item) => item);
      setSuggestions(val);
    }
    setLoading(false);
  }, [query, dataPrices, language]);

  return (
    <div>
      <PricesMain
        background={background}
        crumbsNav={crumbsNav}
        mainTitle={mainTitle}
        loading={loading}
        setLoading={setLoading}
        value={query}
        onChange={(val) => setQuery(val)}
      />
      <PricesContent
        dataSlug={dataSlug}
        // dataPrices={prices}
        suggestions={suggestions}
        loading={loading}
      />
    </div>
  );
};

export default PricesPage;
