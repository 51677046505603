import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';
// import SearcBar from './SearchBar/SearcBar';

const PricesMain = ({
  background,
  crumbsNav,
  mainTitle,
  loading,
  setLoading,
  value,
  onChange,
}) => {
  const langToggle = useLanguage;
  const image = getImage(background.image.localFile);

  return (
    <>
      <section id="scroller-top" className="price-main scroller-top">
        <div className="price-main__bg">
          <GatsbyImage 
            image={image} 
            className="price-main__image" 
            alt={langToggle(
                mainTitle.title_ua,
                mainTitle.title_ru,
                mainTitle.title_en
              )}
          />
        </div>
        <div className="price-main__center">
          <h1 className="h1">
            {langToggle(
              mainTitle.title_ua,
              mainTitle.title_ru,
              mainTitle.title_en
            )}
          </h1>
        </div>
        {/* <SearcBar
          loading={loading}
          setLoading={setLoading}
          value={value}
          onChange={onChange}
        /> */}
      </section>
      <div className="price-main__bottom">
        <ul className="crumbsNav">
          {crumbsNav.map((item, index) => {
            return (
              <li className="crumbsNav-item" key={index}>
                <Link className="crumbsNav-item__link" to={item.to}>
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default PricesMain;
